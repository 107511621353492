<template>
  <div :class="{'in-progress': progressActive,
                'job-shortcut--tablet': !$isGodmode()}"
       class="job-shortcut is-progress-bar">
    <div v-if="job.id"
         :class="[jobStatusObj.class]"
         class="job-shortcut__header">
      <div class="job-shortcut__header-content">
        <div :class="{'job-shortcut__items-wrapper--flex': !showSecondContentColumn}"
             class="job-shortcut__items-wrapper">
          <div class="job-shortcut__item job-shortcut__item--title">
            <span :class="[`job-shortcut__item-icon--${job.sessionType}`]"
                  class="job-shortcut__item-icon"></span>
            {{ jobTitle }}
          </div>
          <div class="job-shortcut__item">{{ jobDate }}</div>
        </div>
        <div v-if="showSecondContentColumn"
             class="job-shortcut__items-wrapper">
          <div v-if="awardedSupplier"
               class="job-shortcut__item">{{ awardedSupplier }}</div>
          <div v-if="awardedSupplierPhone"
               class="job-shortcut__item job-shortcut__item--last">{{ awardedSupplierPhone }}</div>
        </div>
      </div>
      <div class="job-shortcut__actions">
        <button v-if="!isCurrentJob"
                class="sk-btn sk-btn--white job-shortcut__btn"
                @click="onPreviewClick">{{ $gettext('View') }}</button>
        <div v-else>{{ $gettext('Current page') }}</div>
      </div>
    </div>
    <slot class="job-shortcut__content"></slot>
  </div>
</template>

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      job: {
        type: Object,
        default() {
          return {};
        }
      },
      isCurrentJob: Boolean
    },
    data() {
      return {
        progressActive: false,
        jobDetails: ''
      };
    },
    computed: {
      jobStatus() {
        return this.job.status || '';
      },
      jobStatusObj() {
        return helpers.getJobInfo.extendedJobStatusObj(this, this.jobStatus) || {};
      },
      jobTitle() {
        const jobStatus = this.jobStatusObj.text || '';
        return `#${helpers.getJobInfo.jobId(this.job)} - ${jobStatus}`;
      },
      jobDate() {
        const startDateMoment = this.$moment(this.job.startTime);
        const finishDateMoment = this.$moment(this.job.finishTime);

        return `${startDateMoment.format('DD.MM')} ${startDateMoment.format('HH:mm')} - ${finishDateMoment.format('DD.MM')} ${finishDateMoment.format('HH:mm')}`;
      },
      cancellerName() {
        return this.$store.state.OneAssignmentStore.storeJobObj?.cancellerName || '';
      },
      awardedSupplier() {
        return this.job.interpreter?.name || '';
      },
      awardedSupplierPhone() {
        return this.job.interpreter?.phone || '';
      },
      showSecondContentColumn() {
        return !this.isCurrentJob && (this.awardedSupplier || this.awardedSupplierPhone);
      }
    },
    methods: {
      onPreviewClick() {
        if (!this.jobDetails) {
          this.progressActive = true;
          this.$store.dispatch('OneAssignmentStore/getJobDetailsById', this.job.id)
            .then((jobDetails) => {
              this.jobDetails = jobDetails;
              this.progressActive = false;
              this.openPreviewModal();
            })
            .catch((error) => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            });
        } else {
          this.openPreviewModal();
        }
      },
      openPreviewModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'job-preview-modal',
          classes: ['job-preview-modal'],
          width: 375,
          data: {
            data: this.jobDetails
          }
        });
      }
    }
  };
</script>

<style scoped>
  .job-shortcut__header {
    display: flex;
    align-items: center;
    min-height: 55px;
    margin-bottom: 10px;
    padding: 5px 10px 5px 30px;
    background-color: #fff;
  }

  .job-shortcut__header-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .job-shortcut__item {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    min-width: 120px;
    padding: 5px;
    color: #3f3f3f;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .job-shortcut__item-icon {
    position: absolute;
    top: 5px;
    left: -20px;
    display: block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    margin-right: 5px;
    background-position: 50% 50%;
    background-size: 18px auto;
    background-repeat: no-repeat;
  }

  .job-shortcut__item-icon--video {
    background-image: url(~@assets/imgs/assignment_types/posting/video.svg);
    background-image: var(--image-assignment-type-video-icon);
    background-position: 0 50%;
    background-size: 20px auto;
  }

  .job-shortcut__item-icon--phone {
    background-image: url(~@assets/imgs/assignment_types/posting/phone.svg);
    background-image: var(--image-assignment-type-phone-icon);
  }

  .job-shortcut__item-icon--in_person {
    background-image: url(~@assets/imgs/assignment_types/posting/inperson.svg);
    background-image: var(--image-assignment-type-in-person-icon);
    background-size: 15px auto;
  }

  .job-shortcut__item-icon--videoroom {
    background-image: url(~@assets/imgs/assignment_types/posting/videoroom.svg);
    background-image: var(--image-assignment-type-videoroom-icon);
  }

  .job-shortcut__items-wrapper {
    display: block;
    flex-grow: 1;
  }

  .job-shortcut__items-wrapper--flex {
    display: flex;
    flex-wrap: wrap;
  }

  .job-shortcut__content {
    margin-top: 0;
    padding: 0 10px;
  }

  .job-shortcut__items-wrapper:first-child,
  .job-shortcut__items-wrapper--flex .job-shortcut__item:first-child {
    flex-basis: 60%;
  }

  .job-shortcut__items-wrapper:last-child,
  .job-shortcut__items-wrapper--flex .job-shortcut__item:last-child {
    flex-basis: 40%;
  }

  .job-shortcut__actions {
    display: flex;
    justify-content: flex-end;
    min-width: 110px;
    color: #ff5b24;
  }

  .job-shortcut__btn {
    width: auto;
    min-width: 55px;
    padding: 0 10px;
  }

  .job-shortcut__item--title {
    display: flex;
    align-items: center;
    overflow: initial;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.11;
    white-space: normal;
  }

  .job-shortcut__header.assignment__yellow-status .job-shortcut__item--title {
    color: #f93;
  }

  .job-shortcut__header.assignment__green-status .job-shortcut__item--title {
    color: #008489;
  }

  .job-shortcut__header.assignment__dark-blue-status .job-shortcut__item--title {
    color: #003855;
  }

  .job-shortcut__header.assignment__purple-status .job-shortcut__item--title {
    color: #2b255c;
  }

  .job-shortcut__header.assignment__red-status .job-shortcut__item--title {
    color: #f04;
  }

  @media (max-width: 767px) {
    .job-shortcut {
      padding: 5px 15px !important;
      background-color: transparent !important;
    }

    .job-shortcut .job-shortcut__header {
      flex-wrap: wrap;
      margin-bottom: 0;
      padding: 10px 10px 10px 30px;
    }

    .job-shortcut__items-wrapper,
    .job-shortcut__items-wrapper--flex .job-shortcut__item {
      flex-basis: 100% !important;
    }

    .job-shortcut__actions {
      justify-content: flex-start;
      margin-top: 5px;
    }
  }
</style>
