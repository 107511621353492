<template>
  <div class="job-shortcuts">
    <template v-for="(job, index) in jobs">
      <job-shortcut :key="`${job.id}${index}`"
                    :job="job"
                    :is-current-job="isCurrentJob(job)"
                    class="job-shortcuts__item">
        <slot v-if="isCurrentJob(job)"></slot>
      </job-shortcut>
    </template>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import JobShortcut from '@/components/assignment_components/one_assignment/interactive_info/JobShortcut';

  export default {
    components: {
      'job-shortcut': JobShortcut
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        storeJobObj: ({storeJobObj}) => storeJobObj || {},
        seriesJobs: ({storeJobObj}) => storeJobObj.seriesJobs || []
      }),
      jobId() { return this.$route.params.id; },
      jobs() {
        return this.seriesJobs.length
          ? this.seriesJobs
          : [this.storeJobObj];
      }
    },
    methods: {
      isCurrentJob(job = {}) {
        return job.id == this.jobId;
      }
    }
  };
</script>

<style scoped>
  .job-shortcuts__item {
    margin-top: 10px;
  }

  .job-shortcuts__item:first-child {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    .job-shortcuts__item {
      margin-top: 0;
    }

    .job-shortcuts__item:last-child {
      margin-bottom: 10px;
    }
  }
</style>
